$primary: #ff3333;

/* MAIN COLOR */

$secondary: #202020;

/* SECONDARY COLOR */

$blk: #333;

/* DARK GRAY, REASON WHY IT'S SET TO #333 IS SO YOU CAN DARKEN IT IF NEEDED USING darken($blk, 10%); FOR EXAMPLE. YOU COULD ALSO USE 2 VARIABLES INSTEAD */

$wht: #fafafa;
$border-radius: 0px;

/* USE THIS TO SET THE BORDER RADIUS FOR BUTTONS */

$footerLinks: #fafafa;
@import url('https://fonts.googleapis.com/css?family=Open+Sans|Shrikhand');
h1,
h2,
h3 {
    font-family: 'Shrikhand', cursive;
}

p {
    font-family: 'Open Sans', sans-serif;
    font-size: 1.2em;
}

html,
body {
    height: 100%;
}

body {
    overflow-y: scroll;
    -webkit-overflow-scrolling: touch;
}

.flash {
    display: none;
}

q,
blockquote {
    quotes: "“" "”";
}

.navbarFixed {
    position: fixed;
    width: 100%;
    top: 0;
    z-index: 999;
}


/* Change autocomplete styles in WebKit */

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus input:-webkit-autofill,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
    border: 1px solid #0065dd;
    -webkit-text-fill-color: #0065dd;
    -webkit-box-shadow: 0 0 0px 1000px #000 inset;
    transition: background-color 5000s ease-in-out 0s;
}

nav.navbar {
    z-index: 1000;
    border: none;
    border-radius: $border-radius;
    .navbar-nav {
        >li>a {
            text-align: center;
            margin-top: 22px;
            display: flex;
            align-items: center;
            color: $blk;
            @media (max-width: 767px) {
                margin-top: 0;
                padding: 6px 0px;
                display: inline-block;
            }
            &:focus,
            &:active {
                background: transparent;
                color: $blk;
                outline: 0;
            }
            &:hover {
                background: $primary;
                color: $wht;
                @media (max-width: 1024px) {
                    background: transparent;
                    color: $blk;
                    outline: 0;
                }
            }
        }
    }
}

.navbar-right {
    margin-top: 0px;
}

.navbar-toggle {
    margin: 20px 15px 8px 0px;
    border: 1px solid transparent;
    border-radius: 4px;
}


/** LOGIN FORM **/

@mixin btn {
    border: 1px solid $primary;
    border-radius: $border-radius;
    background: $primary;
    color: $wht;
    padding: 0.5em 2em;
    font-size: 1.25em;
    font-weight: bold;
    text-align: center;
    text-transform: uppercase;
    letter-spacing: 1px;
    display: inline-block;
    margin: 2em 0em;
    &:hover {
        background: lighten($primary, 10%);
        color: lighten($wht, 10%);
        box-shadow: 0px 0px 3px lighten($blk, 10%);
        text-decoration: none;
        @media (max-width: 1024px) {
            background: $secondary;
            color: $wht;
            box-shadow: 0px 0px 5px $blk;
        }
    }
}

.btn-default {
    @include btn;
}

.modal-dialog {
    max-width: 300px;
    text-align: center;
    margin: 3em auto;
    @media(max-width: 991px)and(orientation: landscape) {
        margin: 0 auto;
    }
    .close {
        display: none;
    }
    .modal-content {
        color: $primary;
        h2 {
            text-align: center;
        }
    }
    input {
        border: 1px solid #777;
        text-align: center;
    }
    input#username,
    input#password {
        @media (max-width: 1024px) {
            font-size: 16px;
        }
    }
    button {
        background: transparent;
        color: $wht;
        display: block;
        border: none;
        padding: 1em 2em;
        margin: 0 auto;
        font-size: 18px;
        &:hover {
            background: transparent;
            color: #fff;
            box-shadow: none;
            text-shadow: 0px 0px 3px $blk;
            border: none;
        }
    }
    input[type="submit"] {
        @include btn;
        display: block;
        width: 75%;
        font-size: 18px;
        margin: 0 auto;
    }
}

.modal-header,
.modal-footer {
    background: $primary;
    color: $wht;
}

.modal-open {
    overflow: auto;
    padding-right: 0px !important;
}

input#username {
    margin-bottom: 20px;
}


/** END LOGIN FORM **/

footer {
    padding: 50px 0px 20px;
    background: $primary;
    color: $footerLinks;
    font-size: 15px;
    a {
        color: $footerLinks;
        white-space: nowrap;
        font-size: 15px;
        @media (max-width: 990px) {
            font-size: 13px;
        }
        &:hover {
            color: lighten($footerLinks, 10%);
            outline: 0 !important;
            text-decoration: none;
        }
        &:focus {
            color: $wht;
            outline: 0 !important;
            text-decoration: none;
        }
    }
    p {
        font-size: 15px;
        @media (max-width: 990px) {
            font-size: 13px;
        }
    }
}

input#username,
input#password {
    width: 100%;
    @media (max-width: 1024px) {
        font-size: 16px;
    }
}

select {
    -moz-appearance: none;
    text-indent: 0.01px;
    text-overflow: '';
}

a,
a:hover,
a:focus,
a:active {
    outline: 0 !important;
}

.logo {
    max-width: 250px;
    padding: 0.5em;
}

@media(max-width: 767px) {
    .logo {
        max-width: 175px;
    }
    .navbar-header a {
        float: left;
    }
}

@media (max-width: 600px) {
    .logo {
        max-width: 150px;
    }
    .navbar-toggle {
        margin-top: 15px;
    }
}

.top-pad {
    background: white;
    @media (max-width: 767px) {
        h1, h2, h3 {
            font-size: 1em;
        }
        p {
            font-size: 1em;
        }
    }
}

.banner1 {
    @media (max-width: 1399px) {
        background-position: 20% 50%;
    }
    @media (max-width: 1199px) {
        padding: 75px 0;
    }
    background: linear-gradient( (90deg, transparent 20%, $primary 80%)),
    url(../img/banner1.jpg) no-repeat;
    background-size: cover;
    padding: 150px 0;
    background-position: 50%;
    @media (max-width: 650px) {
        background: linear-gradient( rgba($primary, 0.7), rgba($secondary, 0.7)), url(../img/banner1.jpg) no-repeat;
        background-size: cover;
        background-position: 15%;
    }
    h1 {
        @media (max-width: 767px) {
            font-size: 1.5em;
        }
    }
    color: white;
}

.banner2 {
    @media (max-width: 1399px) {
        background-position: 80% 50%;
    }
    @media (max-width: 1199px) {
        padding: 75px 0;
    }
    background: linear-gradient( (270deg, transparent 20%, $secondary 80%)),
    url(../img/banner2.jpg) no-repeat;
    background-size: cover;
    padding: 150px 0;
    background-position: 50%;
    color: white;
    @media (max-width: 650px) {
        background: linear-gradient( rgba($secondary, 0.7), rgba($primary, 0.7)), url(../img/banner2.jpg) no-repeat;
        background-size: cover;
        background-position: 80%;
    }
    h1 {
        @media (max-width: 767px) {
            font-size: 1.5em;
        }
    }
}

.banner {
    .txtBox {
        @media (max-width: 650px) {
            width: 100%;
            margin: 0;
        }
    }
}

.main_banner {
    background: url(../img/banner.jpg) no-repeat;
    background-size: cover;
    background-position: 50% 0%;
    h1 {
        color: white;
        @media (max-width: 767px) {
            font-size: 1.5em;
        }
    }
}

.main-banner-overlay {
    background: rgba(31, 31, 31, .65);
    padding: 350px 0px;
    @media (max-width: 767px) {
        padding: 100px 0px;
    }
}

.midBanner {
    background: url(../img/bigBanner2.jpg) no-repeat;
    background-size: cover;
    padding: 275px 0;
    background-position: 50% 40%;
    p {
        color: white;
    }
    .txtBox {
        background: rgba($secondary, 0.7);
        padding: 50px;
        @media (max-width: 767px) {
            padding: 0px;
        }
    }
    h1 {
        color: white;
        @media (max-width: 767px) {
            font-size: 1.5em;
        }
    }
    @media (max-width: 1199px) {
        padding: 150px 0;
    }
    @media (max-width: 991px) {
        padding: 0px 0;
    }
    .container {
        @media (max-width: 767px) {
            padding: 0px;
        }
    }
}

.btmSection {
    padding: 100px 0;
    @media (max-width: 991px) {
        padding: 50px 0;
    }
    h1 {
        color: $primary;
        @media (max-width: 991px) {
            font-size: 2em;
        }
        @media (max-width: 767px) {
            font-size: 1.5em;
        }
    }
    .iconBox {
        @media (max-width: 650px) {
            width: 100%;
        }
        img {
            @media (max-width: 991px) {
                max-width: 200px;
                width: 100%;
            }
        }
    }
}

.top-pad {
    font-family: 'Open Sans', sans-serif;
    li {
        font-family: 'Open Sans', sans-serif;
    }
}